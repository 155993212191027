<template>
  <div v-if="!$isMobile">
    <div @click="showSurvey = !showSurvey" class="survey-button">
      <img src="../assets/img/survey.png" alt="">
      <span>满意度调研</span>
    </div>
    <div v-if="showSurvey" class="survey-modal">
      <div class="sm-header">
        <CloseOutlined style="opacity: 0;" />
        <span>满意度调研</span>
        <CloseOutlined @click="showSurvey = false" style="color: #999999; font-size: 14px;" />
      </div>
      <p>1、您对留服SaaS管理端使用体验是否满意？<span style="color: #999999;">（匿名）</span></p>
      <div class="sm-scores">
        <span v-for="item in 10" @click="selectScore(item)"
          :class="[currScore == item ? 'score-selected' : 'score-normal']">{{ item }}</span>
      </div>
      <div class="sm-text">
        <span>非常不满意</span>
        <span>非常满意</span>
      </div>
      <p style="margin-bottom: 12px;">2、您还有其他方面的建议吗？<span style="color: #999999;">（选填）</span></p>
      <a-textarea v-model:value="proposal" :maxlength="200" showCount placeholder="请输入..." :rows="4" />
      <div style="text-align: right; margin-top: 40px;">
        <a-button @click="submitSurvey" type="primary">提交</a-button>
      </div>
    </div>
    <div>
      <!-- <h1 class="welcome-h">Hi <span>{{ username }}</span>，欢迎回来！
        <p>Welcome back</p>
      </h1> -->
      <div class="false_head_con">
        <a-row :gutter="16">
          <a-col :span="6">
            <a-card class="haha" title="查重/录入客户" :bordered="false">
              <div class="inputcus">
                <a-input placeholder="请输入客户手机号/微信号" v-model:value.trim="wechatOrPhoneNumberContains"
                  class="inputcus_input haha_input" />
                <a-button type="primary" class="inputcus_btn haha_btn" @click="verifyDuplicate">录入</a-button>
              </div>
            </a-card>
          </a-col>
          <a-col :span="4">
            <a-card class="ht-card"
              style=" height: 100%; background-color: #6672FB; position: relative; overflow: hidden;">
              <a-date-picker style="width: 100px;" v-model:value="moneyDate" picker="month" placeholder="请选择月份"
                @change="getMoney" />
              <div @click="toLink('/fee/settlement')" style="padding-top: 30px; color: #ffffff; cursor: pointer;">
                <p style="text-align: center;"><span style="font-size: 20px; font-weight: bold;">￥{{ money }}</span>元
                </p>
                <div style="display: flex; align-items: center; justify-content: center;">
                  <p style="text-align: center;">提成金额（元）</p>
                  <a-popover placement="top">
                    <template #content>
                      <p class="ls-content">显示藤德留学和留服搜索的提成，四舍五入取整</p>
                    </template>
                    <question-circle-outlined class="ls-icon" />
                  </a-popover>
                </div>
              </div>
              <div class="ht-range1"></div>
              <div class="ht-range2"></div>
            </a-card>
          </a-col>
          <a-col :span="14">
            <a-card title="关注数据" :bordered="false">
              <template #extra>
                <div class="f_row">
                  <p v-if="isShowFocusbtn" @click="goDataGlance">查看数据概览</p>
                  <a-range-picker @change="getFocusData()" class="page-btm" style="width: 230px; background: #F3F4F8;"
                    v-model:value="focusDataTime" :placeholder="['开始时间', '结束时间']" />
                </div>
              </template>
              <a-row :gutter="16">
                <a-col :span="6">
                  <div class="greyCon gc01">
                    <a-statistic title="录入客资(条)" :value="focusData ? focusData.entryCustomerNum : 0">
                      <template #suffix>
                        条
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc02">
                    <a-statistic title="已分配客资(条)" :value="focusData ? focusData.allocatedCustomerNum : 0">
                      <template #suffix>
                        条
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc03">
                    <a-statistic title="分配藤德客资(条)" :value="focusData ? focusData.allocatedCustomerTdNum : 0">
                      <template #suffix>
                        条
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="greyCon gc02" style="cursor: pointer;" @click="toLink('/newCustomer/accurateCustomer')">
                    <a-statistic title="藤德精准客资(条)" :value="focusData ? focusData.accurateCustomerFunding : 0">
                      <template #suffix>
                        条
                      </template>
                    </a-statistic>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div class="false_bottom">
        <a-card title="高意向客户" :bordered="false">
          <template #extra>
            <div class="f_row">
              <p v-if="isShowCusbtn" @click="goLiufuCustomer">查看全部客户</p>
            </div>
          </template>
          <a-table :scroll="{ x: 1200 }" :columns="highCustomercolumns" :dataSource="highCustomerdataSource"
            :pagination="tablePagination" @change="handleTableChange">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'createdDate'">
                {{ getYMDDate(record[column.key]) }}
              </template>
              <template v-if="column.key === 'attributablePerson'">
                <span>{{ record[column.key].name || '-' }}</span>
              </template>
              <template v-if="column.key === 'budget'">
                {{ '￥' + $formatCurrency(record[column.key]) }}
              </template>
              <template v-if="column.key === 'customerPhase'">
                {{ getStatus(record[column.key]) }}
              </template>
              <template v-if="column.key === 'intentionLevel'">
                {{ getIntentionLevelStatus(record[column.key]) }}
              </template>
            </template>
          </a-table>
        </a-card>
      </div>
      <a-modal v-model:open="openModal" title="提示">
        <p style="padding: 20px 0;" v-show="type === 1">
          <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
        </p>
        <p style="padding: 20px 0;" v-show="type === 2">客户与<span style="color: #ff4d4f">{{ detail.entryPerson
            }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客资归属人<span style="color: #ff4d4f">{{
    detail.attribution
  }}</span>，客户阶段为<span style="color: #ff4d4f">{{
      getStatus(detail.customerPhase) }}</span>，创建时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
            }}。</span>
          <span v-if="detail.status === 'LOSS'">当前客户已流入公海，要捞起吗？</span>
        </p>
        <template v-if="type === 1" #footer>
          <a-button @click="close">取消</a-button>
          <a-button type="primary" @click="handleOk">录入</a-button>
        </template>
        <template v-if="type === 2" #footer>
          <div v-if="detail.status === 'LOSS'">
            <a-button @click="close">取消</a-button>
            <a-button type="primary" @click="pickUp">捞起客户</a-button>
          </div>
          <div v-else>
            <a-button type="primary" @click="close">确定</a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
  <div v-else class="m-home">
    <div>
      <a-card style="width: 100%; margin-bottom: 16px;">
        <p>查重/录入客户</p>
        <div class="input-box">
          <input type="text" class="home-input" v-model="wechatOrPhoneNumberContains" placeholder="请输入客户手机号/微信号">
          <span class="hi-button" @click="verifyDuplicate">录入</span>
        </div>
      </a-card>
      <a-card
        style="width: 100%; height: 180px; margin-bottom: 20px; background-color: #6672FB; color:#ffffff;position: relative; overflow: hidden;">
        <a-date-picker v-model:value="moneyDate" picker="month" placeholder="请选择月份" @change="getMoney" />
        <div @click="toLink('/fee/settlement')" style="padding-top: 30px;">
          <p style="text-align: center;"><span style="font-size: 20px; font-weight: bold;">￥{{ money }}</span>元</p>
          <div style="display: flex; align-items: center; justify-content: center;">
            <p style="text-align: center;">提成金额（元）</p>
            <a-popover placement="top">
              <template #content>
                <p class="ls-content">显示藤德留学和留服搜索的提成，四舍五入取整</p>
              </template>
              <question-circle-outlined class="ls-icon" />
            </a-popover>
          </div>
        </div>
        <div class="ht-range1"></div>
        <div class="ht-range2"></div>
      </a-card>
      <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
        <a-col :span="24">
          <a-card title="关注数据" class="m-r_lfcon false_head_con">
            <template #extra>
              <div class="f_row">
                <p v-if="isShowFocusbtn" @click="goDataGlance">查看数据概览</p>
              </div>
            </template>
            <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">

              <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''" class="page-btm"
                style="width: 220px;margin-bottom: 10px;background: #F3F4F8;" v-model:value="focusDataTime"
                :placeholder="['开始时间', '结束时间']" @change="getFocusData" />
            </div>
            <div class="greyCon" style="background-color: #EFF7FF;">
              <a-statistic title="录入客资(条)" :value="focusData ? focusData.entryCustomerNum : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>

            </div>
            <div class="greyCon" style="background-color: #FFF4F5;">
              <a-statistic title="已分配客资(条)" :value="focusData ? focusData.allocatedCustomerNum : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
            <div class="greyCon" style="background-color: #EFF7FF;">
              <a-statistic title="分配藤德客资(条)" :value="focusData ? focusData.allocatedCustomerTdNum : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
            <div class="greyCon" style="background-color: #FFF4F5;" @click="toLink('/newCustomer/accurateCustomer')">
              <a-statistic title="藤德精准客资(条)" :value="focusData ? focusData.accurateCustomerFunding : 0">
                <template #suffix>
                  条
                </template>
              </a-statistic>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div class="false_bottom">
        <a-card title="高意向客户" :bordered="false">
          <template #extra>
            <div class="f_row">
              <p v-if="isShowCusbtn" @click="goLiufuCustomer">查看全部客户</p>
            </div>
          </template>
          <a-table :scroll="{ x: 1200 }" :columns="highCustomercolumns" :dataSource="highCustomerdataSource"
            :pagination="tablePagination" @change="handleTableChange">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'createdDate'">
                {{ getYMDDate(record[column.key]) }}
              </template>
              <template v-if="column.key === 'attributablePerson'">
                <span>{{ record[column.key].name || '-' }}</span>
              </template>
              <template v-if="column.key === 'budget'">
                {{ '￥' + $formatCurrency(record[column.key]) }}
              </template>
              <template v-if="column.key === 'customerPhase'">
                {{ getStatus(record[column.key]) }}
              </template>
              <template v-if="column.key === 'intentionLevel'">
                {{ getIntentionLevelStatus(record[column.key]) }}
              </template>
            </template>
          </a-table>
        </a-card>
      </div>
      <a-modal v-model:open="openModal" title="提示">
        <p style="padding: 20px 0;" v-show="type === 1">
          <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
        </p>
        <p style="padding: 20px 0;" v-show="type === 2">客户与<span style="color: #ff4d4f">{{ detail.entryPerson
            }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客资归属人<span style="color: #ff4d4f">{{
    detail.attribution
  }}</span>，客户阶段为<span style="color: #ff4d4f">{{
      getStatus(detail.customerPhase) }}</span>，创建时间：<span style="color: #ff4d4f">{{ getDate(detail.createdDate)
            }}。</span>
          <span v-if="detail.status === 'LOSS'">当前客户已流入公海，要捞起吗？</span>
        </p>
        <template v-if="type === 1" #footer>
          <a-button @click="close">取消</a-button>
          <a-button type="primary" @click="handleOk">录入</a-button>
        </template>
        <template v-if="type === 2" #footer>
          <div v-if="detail.status === 'LOSS'">
            <a-button @click="close">取消</a-button>
            <a-button type="primary" @click="pickUp">捞起客户</a-button>
          </div>
          <div v-else>
            <a-button type="primary" @click="close">确定</a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import chainIndex from "@/components/home/chainIndex";
import { getProfile } from "../utils/session";
import TablePage from "@/components/TablePage";
import dayjs from "dayjs";
import numeral from "numeral";
export default {
  name: "Home",
  components: {
    chainIndex,
    TablePage
  },
  data() {
    return {
      permissions: null,
      isShowFocusbtn: true,
      isShowCusbtn: true,
      highCustomerdataSource: [],
      highCustomercolumns: [],
      tablePagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: total => `共 ${total} 条`,
        showSizeChanger: false,
      },
      focusData: null,
      focusDataTime: [],
      profile: null,
      wechatOrPhoneNumberContains: '',
      openModal: false,
      type: 0,
      detail: {},
      moneyDate: '',
      money: 0,

      showSurvey: false,
      currScore: 10,
      proposal: ''
    }
  },
  async mounted() {
    try {
      this.profile = await getProfile();
      this.permissions = this.profile.everyPermissions;
      if (!this.permissions('/home/numberOfInstitutions')) {
        this.isShowFocusbtn = false;
      } else {
        this.isShowFocusbtn = true;
      }
      if (!this.permissions('/agency/institutionsCustomer/list')) {
        this.isShowCusbtn = false;
      } else {
        this.isShowCusbtn = true;
      }
    } catch ({ message, status }) {
      if (status !== 401) {
        this.$message.error(message);
      }
    }

    this.getMouth()
    this.getFocusData()
    this.getHighCustomerList()
    this.getDay();
  },

  methods: {
    getDay() {
      this.moneyDate = dayjs(dayjs().format('YYYY-MM'));
      this.royaltyAmount();
    },

    getMoney() {
      this.royaltyAmount();
    },

    //获取提成金额
    async royaltyAmount() {
      try {
        let res = await this.$http.get('/homepage/getAllCommissionAmount', {
          yearMonth: this.moneyDate.format('YYYY-MM')
        });
        if (res.code == '200') {
          this.money = numeral(res.data).format('0,0.00');
        }
      } catch ({ message }) {

      }
    },

    handleTableChange(e) {
      this.tablePagination = {
        ...this.tablePagination,
        current: e.current
      };
      this.getHighCustomerList();
    },
    async getHighCustomerList() {
      this.tablePagination = {
        ...this.tablePagination,
        current: this.tablePagination.current
      };
      let { current, pageSize } = this.tablePagination
      try {
        let res = await this.$http.post('/homepage/getCustomerList', {
          size: pageSize,
          page: current,
          sort: '-createdDate'
        });
        if (res.code == '200') {
          this.highCustomerdataSource = res.data
          this.highCustomercolumns = [
            {
              title: '创建时间',
              dataIndex: 'createdDate',
              key: 'createdDate',
              width: 120,
              align: "center",
              fixed: 'left',
            },
            {
              title: '客资归属人',
              dataIndex: 'attributablePerson',
              width: 100,
              key: 'attributablePerson',
              ellipsis: true
            },
            {
              title: '客户姓名',
              dataIndex: 'name',
              width: 100,
              key: 'name',
            },
            {
              title: '手机号',
              dataIndex: 'phoneNumber',
              width: 130,
              key: 'phoneNumber',
            },
            {
              title: '微信号',
              dataIndex: 'wechat',
              width: 100,
              key: 'wechat',
            },
            {
              title: '客户阶段',
              dataIndex: 'customerPhase',
              width: 130,
              key: 'customerPhase',
            },
            {
              title: '意向国家',
              dataIndex: 'intendedCountries',
              width: 130,
              key: 'intendedCountries',
            },
            {
              title: '预算(万)',
              dataIndex: 'budget',
              key: 'budget',
              width: 150,
              align: "center"
            },
            {
              title: '意向等级',
              dataIndex: 'intentionLevel',
              width: 130,
              key: 'intentionLevel',
            },
            {
              title: '其他信息',
              dataIndex: 'remarks',
              width: 150,
              key: 'remarks',
              ellipsis: true
            },
          ]
          this.tablePagination = { ...this.tablePagination, total: res.rows };
        }
      } catch ({ message }) {
        this.$message.error(message)
      }
    },
    async getFocusData() {
      let createdDateOnOrAfter = this.focusDataTime[0].format('YYYY-MM-DD 00:00:00');
      let createdDateOnOrBefore = this.focusDataTime[1].format('YYYY-MM-DD 23:59:59')
      try {
        let res = await this.$http.post('/homepage/getConcernData', { createdDateOnOrAfter, createdDateOnOrBefore });
        if (res.code == '200') {
          this.focusData = res.concern
        }
      } catch ({ message }) {
        this.$message.error(message)
      }
    },
    goLiufuCustomer() {
      this.$router.push({
        path: '/newCustomer/institutionsCustomer',
      })
    },
    goDataGlance() {
      this.$router.push({
        path: '/dataReport/dataGlance',
      })
    },

    getMouth() {
      let today = dayjs().format('YYYY-MM-DD')
      let firstDay = today.split('-')[0] + '-' + today.split('-')[1] + '-01'
      firstDay = dayjs(firstDay).format('YYYY-MM-DD' + ' 00:00:00')
      today = dayjs(today).format('YYYY-MM-DD' + ' 23:59:59')
      let month = []
      month[1] = dayjs(today)
      month[0] = dayjs(firstDay)
      this.value = month
      this.pieValue = month
      this.lineValue = month
      this.assignCustomersValue = month
      this.receivingCustomersValue = month
      this.focusDataTime = month
      return month
    },

    //获取部门数据
    async verifyDuplicate() {
      if (!this.wechatOrPhoneNumberContains) {
        this.$message.error('请输入微信号/手机号');
        return
      }
      try {
        let res = await this.$http.get('/verifyDuplicate', {
          wechatOrPhoneNumberIs: this.wechatOrPhoneNumberContains
        });
        if (res.code == '200') {
          this.detail = res;
          this.type = 2;
          this.openModal = true;
        } else if (res.code == '201') {
          this.type = 1;
          this.openModal = true;
        }
      } catch ({ message }) {

      }
    },

    // 捞起
    async pickUp() {
      try {
        let res = await this.$http.post('/callingUpTheCustomer', {
          id: this.detail.id
        });
        if (res.code == '200') {
          this.$message.success('捞起成功');
          this.openModal = false;
        } else {
          this.$message.error('捞起失败');
        }
      } catch ({ message }) {
      }
    },

    handleOk() {
      this.$router.push({
        path: '/newCustomer/institutionsCustomer',
        query: {
          type: 'add',
          wp: this.wechatOrPhoneNumberContains
        }
      })
      this.wechatOrPhoneNumberContains = '';
      this.openModal = false;
    },

    close() {
      this.wechatOrPhoneNumberContains = '';
      this.openModal = false;
    },

    getStatus(val) {
      switch (val) {
        case 'NEW_CUSTOMER_CAPITAL':
          return '新客咨';
        case 'CONTACTS':
          return '已联系上';
        case 'IN_INTENTION':
          return '确定意向中';
        case 'TO_BE_ALLOCATED':
          return '待分配';
        case 'ALLOCATED':
          return '已分配';
        case 'MULTIPLE_ASSIGNMENT':
          return '多次分配';
        default:
          break
      }
    },
    getIntentionLevelStatus(val) {
      switch (val) {
        case 'HIGH':
          return '高';
        case 'MEDIUM':
          return '中';
        case 'LOW':
          return '低';
        case 'INVALID':
          return '无效';
        default:
          break
      }
    },
    getDate(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    },
    getYMDDate(val) {
      return dayjs(val).format('YYYY-MM-DD');
    },

    toLink(path) {
      this.$router.push(path);
    },

    selectScore(val) {
      this.currScore = val;
    },

    async submitSurvey() {
      try {
        let res = await this.$http.post('/satisfaction/survey/save', {
          score: this.currScore,
          remark: this.proposal
        });
        if (res.code == '200') {
          this.$message.success('您已提交成功，感谢反馈！');
          this.showSurvey = false;
          this.currScore = 10;
          this.proposal = '';
        }
      } catch ({ message }) {
      }
    }

  }
}
</script>

<style scoped lang="scss">
.false_head_con {
  .greyCon {
    height: 100px;
  }

  .inputcus {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;

    input {
      margin-right: 10px;
    }
  }

  .f_row {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-right: 10px;
      color: rgb(110, 183, 252);
      cursor: pointer;
    }
  }
}

.false_bottom {
  margin-top: 16px;

  p {
    color: rgb(110, 183, 252);
    cursor: pointer;
  }

  :deep(.ant-table-content) {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px; // 横向滚动条
      height: 6px; // 纵向滚动条 必写
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }
  }
}

.welcome-h {
  margin: 100px 0 0 100px;
  font-size: 25px;
  display: inline-block;

  span {
    color: #007BEF;
    font-weight: bold;
  }

  p {
    font-size: 15px;
    color: #999999;
    text-align: right;
    margin-right: 22px;
  }
}

.greyCon {
  background-color: #f1f1f1;
  padding: 15px;
  min-width: 130px;
}

:deep(.r_lfcon .ant-card-body) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .greyCon {
    width: 22%;
    margin: 0 10px;
    min-width: 180px;
  }
}

:deep(.ant-statistic-content-prefix) {
  font-size: 14px;
}

:deep(.ant-statistic-content-suffix) {
  font-size: 14px;
}

:deep(.ant-table-wrapper .ant-table-thead >tr>th) {
  background: none;
}

.icon_num {
  background-color: #1156d6 !important;
  color: #fff;
}

.icon_num_all {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: #ddd;

}

.f_con {
  :deep(.ant-card-body) {
    height: 330px;
    min-height: 330px;
  }
}

:deep(.ant-card-body) {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
}

:deep(.m-r_lfcon .ant-card-body) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .greyCon {
    width: 100%;
    margin-bottom: 10px;
  }
}

.m-home {

  .m-welcome-h {
    padding: 20px;
  }

  :deep(.ant-card-body) {
    padding: 10px;
  }

  :deep(.ant-card-head) {
    padding: 6px;
  }

  .input-box {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  .home-input {
    background: none;
    outline: none;
    border: none;
    width: 170px;
    background-color: #E8EDFF;
    padding: 8px 12px;
    border-radius: 4px;
  }

  .hi-button {
    background-color: #6672FB;
    padding: 4px 12px;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 12px;
  }
}

:deep(.ant-table-thead > tr >th) {
  background: #E8EDFF !important;
}
</style>
<style lang="scss">
.rangStyle {
  .ant-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
    flex-direction: column;
  }
}

.haha {
  background: lavender;
}

.inputcus_input.haha_input {
  height: 50px;
  border-color: #4096ff;
  border-radius: 30px;
  padding-left: 20px;
}

.inputcus_btn.haha_btn {
  height: 50px;
  border-radius: 30px;
}

.greyCon.gc01 {
  background: #e1f4ff !important;
}

.greyCon.gc02 {
  background: #fff4f5 !important;
}

.greyCon.gc03 {
  background: #e9f8ff !important;
}

.ht-range1 {
  background-color: rgba($color: #FFFFFF, $alpha: 0.06);
  width: 172px;
  height: 172px;
  position: absolute;
  top: -60px;
  right: -60px;
  border-radius: 50%;
}

.ht-range2 {
  background-color: rgba($color: #FFFFFF, $alpha: 0.06);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 30px;
  bottom: -40px;
  border-radius: 50%;
}

.survey-button {
  position: fixed;
  right: 60px;
  bottom: 120px;
  box-shadow: 0px 4px 40px 8px #00000014;
  border-radius: 16px;
  padding: 14px;
  background-color: #ffffff;
  font-size: 14px;
  color: #3385FF;
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 2px;
  }
}

.survey-modal {
  position: fixed;
  right: 180px;
  bottom: 120px;
  box-shadow: 0px 4px 40px 8px #00000014;
  padding: 24px;
  z-index: 2;
  border-radius: 16px;
  min-width: 420px;
  background-color: #ffffff;

  .sm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      font-size: 20px;
    }
  }

  .sm-scores {
    display: flex;
    padding: 12px 0;
  }

  .score-selected {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #3385FF;
    line-height: 24px;
    text-align: center;
    margin-right: 8px;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px
  }

  .score-normal {
    @extend .score-selected;
    background-color: #F3F7FA;
    color: #333333;
  }
}

.sm-text {
  margin-bottom: 20px;
  color: #666666;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666666;

  span {
    cursor: pointer;
  }
}
</style>